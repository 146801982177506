.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;

    .hamburger-box {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
    }

    .hamburger-inner {
        top: 50%;
        display: block;
        margin-top: -2px;
        background-color: #fff;
        position: absolute;
        width: 40px;
        height: 5px;
        transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        transition-duration: .22s;

        &::after, &::before {
            position: absolute;
            width: 40px;
            height: 5px;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
            background-color: #fff;
            display: block;
            content: "";
        }

        &::before {
            top: -12px;
            transition: top .1s ease-in .25s,opacity .1s ease-in;
        }

        &::after {
            bottom: -12px;
            transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
        }
    }

    &.is-active{
        .hamburger-inner {
            transition-delay: .12s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
            transform: rotate(225deg);

            &::before {
                top: 0;
                transition: top .1s ease-out,opacity .1s ease-out .12s;
                opacity: 0;
            }

            &::after {
                bottom: 0;
                transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
                transform: rotate(-90deg);
            }
        }
    }
}