.btn {
    font-family: $font-roboto;
    border: none;
    padding: 0 35px;
    font-weight: 700;
    transition: background-color 0.2s;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    line-height: 46px;
    color: white;
    background-color: $color-main;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:hover {
        background-color: darken($color-main, 7%);
        text-decoration: none;
    }

    &--gray {
        color: white;
        background-color: $color-gray;

        &:hover {
            background-color: lighten($color-gray, 7%);
        }
    }

    &--check-mark-circle, &--check-mark-edit, &--data-validate, &--angle-right-circle {
        &::after {
            @extend .im;
            display: inline-block;
            margin-left: 9px;
            vertical-align: middle;
            position:relative;
            top:-1px;
            font-size:1em;
        }
    }

    &--check-mark-circle {
        &::after {
            content: "\e024";
        }
    }

    &--check-mark-edit {
        &::after {
            content: "\e03b";
        }
    }

    &--data-validate {
        &::after {
            content: "\e111";
        }
    }

    &--angle-right-circle {
        &::after {
            content: "\e005";
        }
    }

    &--spaced {
        margin-right: 2em;
    }
}