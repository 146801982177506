#ajax-spinner {
    position: fixed;
    background-color: rgba(50, 50, 50, 0.2);
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
}

#ajax-spinner:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: -23px 0 0 -23px;
    border-radius: 50%;
    //border: 5px solid $color-main;
    //border-color: $color-main transparent $color-main transparent;
    //animation: lds-dual-ring 1.2s linear infinite;
    background-color: $color-main;
    animation: sk-scaleout 0.5s infinite ease-in-out;
}
//
//@keyframes lds-dual-ring {
//    0% {
//        transform: rotate(0deg);
//    }
//    100% {
//        transform: rotate(360deg);
//    }
//}


@-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
          opacity: 0;
      }
}