// fonts -----------------------
$font-roboto: 'Roboto', sans-serif;
$font-size-root: 16px !default;
$line-height-root: 1.4 !default;

// paths -----------------------
// images
$images-path: '../images';

// colors ----------------------
$color-burgundy: #C70339;
$color-gray: #635d5d;
$color-light-gray: #B4B4B4;
$color-almost-black: #282424;
$color-almost-white: #F4F4F4;

// colors elements
$color-main: $color-burgundy;
$color-text: $color-gray;
$bgcolor-body: $color-almost-white;

// Media queries breakpoints ---------------------------------------------------
$screen-min-width: 320px;

$screen-xs: 0px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: ($screen-xl - 1);

// bootstrap variables ---------------------------------------------------------

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;