.white-popup {
    position: relative;
    background: #FFF;
    padding: 20px;
    width: auto;
    max-width: 500px;
    margin: 20px auto;
}

.popup-content{
    padding-top:15px;
    font-size:15px;
}