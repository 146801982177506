.offers-list{
    margin-bottom:20px;

    & > div{
        margin-bottom:40px;
    }
}

.offer {
    background-color: white;
    height:100%;

    .img {
        //overflow: hidden;
        //height: 205px;
        display: flex;
        justify-content: center;

        img {
            //width: auto;
            //max-width:none;
            //height: 205px;
        }
    }

    .offer-content {
        padding: 20px 25px 60px;

        a{
            text-decoration: none;
        }

        .title {
            color: $color-main;
            font-size: 20px;
            font-weight: 900;
            text-transform:uppercase;
            line-height:1.1;

            @media screen and (min-width: $screen-md) {
                font-size: 24px;
            }

            .bigger {
                font-size: 35px;

                @media screen and (min-width: $screen-md) {
                    font-size: 44px;
                }
            }
        }

        .subtitle {
            color: $color-almost-black;
            font-size: 24px;
            font-weight: 900;
            text-transform:uppercase;
            margin-bottom:10px;
        }

        .description{
            font-size:16px;
            margin-bottom:20px;
            line-height:1.5;
            min-height:75px;
        }

        .link-arrow{
            position:absolute;
            bottom:30px;
            left:40px;
        }
    }
}