%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.clear {
    clear: both;
}

.hide {
    display: none;
}

.small{
    font-size:0.8em;
}

.bold, strong, b {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.underline {
    text-decoration: underline;
}

.inline {
    display: inline;
}

.nowrap {
    white-space: nowrap;
}

.no-transform {
    text-transform: none;
}

.mt-6per {
    margin-top: 6%;
}

.mb-6per {
    margin-bottom: 6%;
}

.mt-20 {
    margin-top: 20px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}

.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

// colors
.color-main, .highlight {
    color: $color-main;
}

.color-gray {
    color: $color-light-gray;
}