.top-banner {
    background: #d9d9d9;
    background: -moz-linear-gradient(left, #d9d9d9 0%, #b4b4b4 100%);
    background: -webkit-linear-gradient(left, #d9d9d9 0%, #b4b4b4 100%);
    background: linear-gradient(to right, #d9d9d9 0%, #b4b4b4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9d9d9', endColorstr='#b4b4b4', GradientType=1);
    overflow: hidden;

    .banner-item {
        display: flex;
        align-items: center;
        min-height: 410px;
        position: relative;

        @media screen and (min-width: $screen-md) and (max-width: $screen-lg-max) {
            min-height: 350px;
        }

        .text {
            position: relative;
            z-index: 10;
            margin: 0 auto;
            padding: 30px 0 270px;
            max-width: 350px;
            //overflow: hidden;

            @media screen and (min-width: $screen-md) {
                width: 44%;
                margin: 0;
                padding: 0px;
            }

            @media screen and (min-width: $screen-lg) {
                padding-left: 3%;
                max-width: 450px;
            }

            a{
                text-decoration: none;
            }

            h2 {
                text-transform: uppercase;
                font-size: 40px;
                margin: 0 0 20px 0;
                line-height: 1;
                color: $color-almost-black;
                font-weight: 900;

                @media screen and (min-width: $screen-xl) {
                    font-size: 50px;
                }

                .bigger {
                    font-size: 70px;

                    @media screen and (min-width: $screen-md) {
                        font-size: 80px;
                    }

                    @media screen and (min-width: $screen-xl) {
                        font-size: 100px;
                    }
                }
            }

            p {
                font-size: 17px;
                line-height: 1.4;
                margin: 0 0 25px 0;

                @media screen and (min-width: $screen-xl) {
                    line-height: 1.6;
                    font-size: 18px;
                }
            }
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin: 0 -15px;
            overflow:hidden;

            @media screen and (min-width: $screen-md) {
                margin: 0;
                right: 0px;
                left: 45%;
                align-items: center;
                justify-content: center;
                overflow: hidden;

            }

            img {
                height: 250px;
                width: auto;
                max-width: none;

                @media screen and (min-width: $screen-md) {
                    height: 100%;
                }

                &.img-face{
                    @media screen and (min-width: $screen-md) {
                        height: 400px;
                    }

                    @media screen and (min-width: $screen-xl) {
                        height: 450px;
                    }
                }
            }
        }

        &.banner-detail {
            display: block;

            @media screen and (min-width: $screen-md) {
                display: flex;
            }

            .text {
                padding-top: 20px;
                padding-bottom: 30px;

                @media screen and (min-width: $screen-md) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-left: 58%;
                }

                @media screen and (min-width: $screen-lg) {
                    margin-left: 56%;
                }

                .partner-logo {
                    max-height: 70px;
                    margin-bottom: 5px;
                }

                h2 {
                    font-size: 35px;
                    line-height: 1.2;
                    margin-bottom: 30px;

                    @media screen and (min-width: $screen-lg) {
                        font-size: 40px;
                    }

                    .bigger {
                        font-size: 75px;

                        @media screen and (min-width: $screen-lg) {
                            font-size: 90px;
                        }
                    }
                }
            }

            .image {
                align-items: center;
                position: relative;
                max-height: 220px;

                @media screen and (min-width: $screen-md) {
                    max-height: none;
                    height: 100%;
                    position: absolute;
                    right: 45%;
                    left: 0;
                }

                @media screen and (max-width: $screen-sm-max) {
                    img {
                        height: auto;
                        max-height: 250px;
                    }
                }
            }
        }
    }
}