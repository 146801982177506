.main-content {
    padding: 55px 0;

    * ~ h3 {
        margin-top: 1.5em;
    }

    .text-ico {
        font-size: 1em;
        position: relative;
        vertical-align: middle;
        top: -2px;
        margin: 0 3px 0 0;
    }

    ul {
        &.numbered {
            counter-reset: counter;

            li {
                padding-left: 25px;
                position: relative;

                &::before {
                    content: counter(counter) ")";
                    counter-increment: counter;
                    position: absolute;
                    left: 0;
                    color: $color-main;
                }
            }
        }

        &.arrow-indent {
            li {
                padding-left: 30px;
                position: relative;
                margin-bottom: 10px;

                &::before {
                    @extend .im;
                    content: '\e001';
                    position: absolute;
                    top: 3px;
                    left: 0;
                    color: $color-light-gray;
                    font-size: 0.9em;
                }
            }

            &.bigger-gaps {
                li {
                    margin: 1em 0 2em;
                }
            }
        }
    }

    .title-desc-card {
        @media screen and (max-width: $screen-sm-max) {
            img {
                display: block;
                width: 400px;
                margin: 20px auto 0;
            }
        }

        @media screen and (min-width: $screen-md) {
            position: relative;
            padding-right: 45%;

            .luxor-kk-card {
                position: absolute;
                right: 0;
                top: 0;
                max-width: 45%;
            }
        }

        @media screen and (min-width: $screen-lg) {
            padding-right: 50%;

            .luxor-kk-card {
                max-width: 50%;
            }
        }
    }

    .premium-right-banner {
        max-width: 370px;
        margin: 50px auto 0;

        @media screen and (min-width: $screen-lg) {
            max-width: none;
            margin: 150px 0 0;
        }

        @media screen and (min-width: $screen-xl) {
            margin-top: 250px;
        }
    }
}

.contact-banner {
    height: 150px;
    background: url('#{$images-path}/kontakt-top.jpg') no-repeat center center;
    background-size: cover;

    @media screen and (min-width: $screen-lg) {
        height: 230px;
    }
}
