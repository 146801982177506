label {
    //font-size:17px;
    display: block;
    margin-bottom: 3px;

    @media screen and (min-width: $screen-md) {
        font-size: 17px;
        margin-bottom: 5px;
    }

    &.required {
        &::after {
            content: "*";
            color: $color-main;
            font-weight: normal;
            padding-left: 5px;
        }
    }
}

.form-control {
    display: block;
    width: 100%;
    background: white;
    line-height: 45px;
    border: 1px solid $color-light-gray;
    border-radius: 3px;
    padding: 0 15px;
    color: $color-text;
    font-family: $font-roboto;
    margin-bottom: 15px;

    @media screen and (min-width: $screen-lg) {
        margin-bottom: 25px;
    }

    &:focus {
        outline: none;
    }

    & ~ .help-block, & ~ span .help-block {
        display: block;
        margin-top: -11px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 15px;

        @media screen and (min-width: $screen-lg) {
            margin-top: -21px;
        }

        &.text-danger {
            color: $color-burgundy;
        }
    }
}

textarea {
    height: 150px;
    resize: vertical;
}

select {
    cursor: pointer;
    appearance: none;

    &.form-control {
        background-image: url("#{$images-path}/select-arrow.svg");
        background-position: right center;
        background-size: contain;
        background-repeat: no-repeat;
        padding-right: 40px;
        padding-top: 0;
        padding-bottom: 0;
        height: 47px;
        text-align: left;
    }

    &::-ms-expand {
        display: none;
    }

    option {
        padding: 2px;
        font-size: 16px;

        &[value=""] {
            color: #ccc;
        }
    }
}

// target Internet Explorer 9 and Internet Explorer 10
@media screen and (min-width: 0 \0
) {
    select {
        background: none;
        padding: 5px;
    }
}

// checkboxes
.checkbox {
    position: relative;
    margin-bottom: 5px;

    input[type="checkbox"] {
        position: absolute;
        margin: 5px 0 0 5px;

        & + label {
            line-height: normal;
            display: table-cell;
            vertical-align: middle;
            margin-bottom: 0;
            height: 30px;
            position: relative;
            padding-left: 38px;
            cursor: pointer;

            &::before {
                position: absolute;
                content: "";
                height: 30px;
                width: 30px;
                border: 1px solid $color-light-gray;
                border-radius: 3px;
                background-color: white;
                top: 0;
                left: 0;
            }
        }

        &:checked + label::after {
            @extend .im;
            position: absolute;
            content: "\e023";
            top: 5px;
            left: 6px;
            color: $color-main;
            font-size: 20px;
        }
    }
}