.newsletter {
    background-color: white;
    padding: 60px 0 70px 0;
    text-align: center;

    h2 {
        font-weight: 100;
        color: #282424;
        margin: 0 0 20px 0;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 1px;

        @media screen and (min-width:$screen-sm) {
            font-size: 32px;
            letter-spacing: 3px;
        }
    }

    p {
        margin-bottom: 50px;
        font-size: 16px;
        line-height:1.6;
    }
}

.registration-done {
    .main-content {
        .container {
            min-height: 500px;
        }
    }
    .newsletter {
        display: none;
    }
}
