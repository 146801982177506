.bx-wrapper {
    position: relative;

    .bx-controls {
        position: absolute;
        bottom: 5px;
        left:0;
        right:0;

        .bx-pager {
            text-align: center;

            .bx-pager-item {
                display: inline-block;

                a {
                    background-color: #D3D3D3;
                    text-indent: -9999px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                    outline: 0;
                    border-radius: 50%;

                    &.active {
                        background-color: #949494;
                    }
                }
            }
        }
    }
}