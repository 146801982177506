.is-submenu {
    margin-top: 56px;

    @media screen and (min-width: $screen-sm) {
        margin-top: 51px;
    }
}

.submenu {
    background-color: white;

    position: fixed;
    width: 100%;
    z-index: 11;

    @media screen and (max-width: $screen-xs-max) {
        ul {
            margin: 0 -15px;
            display: flex;

            li {
                flex: 0 1 50%;
                display: flex;
                align-items: center;
                height: 56px;

                a {
                    flex: 1 1 100%;
                    display: block;
                    color: $color-text;
                    text-decoration: none;
                    padding: 8px 13px;
                    position: relative;
                    font-size: 15px;
                    text-align: center;
                    transition: background-color 0.2s;
                }

                & + li {
                    border-left: 1px solid #D8D8D8;
                }

                &.active {
                    background-color: #D8D8D8;
                }
            }
        }
    }

    @media screen and (min-width: $screen-sm) {
        text-align: right;

        ul {
            li {
                display: inline-block;

                a {
                    display: inline-block;
                    color: $color-text;
                    text-decoration: none;
                    padding: 15px 10px;
                    position: relative;
                    font-size: 15px;

                    &::before, &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        height: 3px;
                        background-color: #989696;
                    }

                    &::before {
                        left: 50%;
                        right: 50%;
                        transition: right 0.2s;
                    }

                    &::after {
                        right: 50%;
                        left: 50%;
                        transition: left 0.2s;
                    }

                    &:hover {
                        &::before {
                            right: 0;
                        }

                        &::after {
                            left: 0;
                        }
                    }
                }

                &.active {
                    a {
                        &::before {
                            right: 0;
                        }

                        &::after {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}