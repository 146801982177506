a {
    color: $color-main;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.link-arrow {
    display: inline-block;
    color: $color-main;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    padding: 8px 0 7px 45px;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;

    &::before {
        @extend .im;
        content: "\e001";
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: $color-main;
        color: white;
        text-align: center;
        line-height: 34px;
        height: 35px;
        width: 35px;
        font-size: 1em;

    }

    &:hover {
        text-decoration: underline;
    }

    &.bigger {
        font-size: 22px;
        padding: 10px 0 8px 60px;

        &::before {
            line-height: 47px;
            height: 47px;
            width: 47px;
        }
    }
}