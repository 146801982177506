.footer {
    background-color: $color-almost-black;
    padding: 70px 0;

    .logos {
        margin-bottom: 20px;

        @media screen and (min-width: $screen-lg) {
            margin-bottom: 0px;
        }

        img {
            height: 21px;
            max-width: 30%;

            @media screen and (max-width: $screen-xs-max) {
                max-width: 26%;
            }

            & + img {
                margin-left: 25px;
            }
        }
    }

    .footer-menu {
        text-align: left;

        @media screen and (min-width: $screen-lg) {
            text-align: right;
        }

        .nav {
            @media screen and (min-width: $screen-md) {
                display: inline-block;
            }

            li {
                @media screen and (min-width: $screen-lg) {
                    display: inline-block;
                }

                a {
                    display: inline-block;
                    padding: 5px 9px 10px 0;
                    font-size: 15px;
                    color: #AAAAAA;
                    text-decoration: none;

                    @media screen and (min-width: $screen-lg) {
                        padding: 4px 7px;
                        font-size: 13px;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .social {
        text-align: right;

        ul {

            li {
                margin: 7px 0 0 0;

                @media screen and (min-width: $screen-lg) {
                    display: inline-block;
                    margin-top: 0;
                }

                @media screen and (min-width: $screen-lg) and (max-width: $screen-lg-max) {
                    margin-top: 20px;
                }

                & + li {
                    margin-top: 35px;

                    @media screen and (min-width: $screen-lg) {
                        margin-left: 20px;
                        margin-top: 0px;
                    }
                }

                a {
                    display: inline-block;

                    img {
                        height: 25px;
                        transition: opacity 0.2s;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}