.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 170px;
    background: #B70E30 url("#{$images-path}/bg-header.jpg") center center repeat-x;
    min-width: $screen-min-width;
    z-index: 100;

    .logos {
        float: left;
        margin-top: 40px;
        max-width: calc(100% - 95px);
        margin-left: 10px;

        @media screen and (min-width: $screen-sm) {
            margin-left: 0;
        }

        @media screen and (min-width: $screen-md) {
            max-width: none;
        }

        .logo-luxor {
            display: inline-block;

            img {
                height: 32px;
            }
        }

        .logo-kk {
            display: inline-block;
            margin-top: 20px;

            @media screen and (min-width: $screen-md) {
                margin-left: 70px;
                margin-top: 0px;
            }

            img {
                height: 32px;
            }
        }

        @media screen and (min-width: $screen-md) {
            br {
                display: none;
            }
        }

    }

    .hamburger {
        float: right;
        margin-top: 55px;

        @media screen and (min-width: $screen-md) {
            display: none;
        }
    }

    .navigation {
        @media screen and (max-width: $screen-sm-max) {
            display: none;
            position: fixed;
            top: 170px;
            width: 100%;
            left: 0;
            background-color: $color-almost-white;
            box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
            padding: 15px 0;
            text-align: center;

            .navbar-nav {
                max-width: 540px;
                margin: 0 auto;
                padding: 0 25px;

                li {

                    a {
                        display: block;
                        padding: 7px 15px;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;

                        }
                    }

                    &.active {
                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .login-partners {
                display: inline-block;
                margin: 15px 25px 0;
                color: $color-main;
                font-size: 13px;
                padding: 5px 7px;
                border: 1px solid $color-main;
                border-radius: 5px;
                text-decoration: none;
                transition: all 0.2s;
            }
        }

        @media screen and (min-width: $screen-md) {
            display: block !important;
            position: absolute;
            right: 15px;
            margin-top: 95px;

            .navbar-nav {
                li {
                    display: inline-block;

                    a {
                        display: inline-block;
                        color: #E9D4D5;
                        text-decoration: none;
                        padding: 10px;
                        position: relative;
                        font-size: 16px;

                        &::before, &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            height: 3px;
                            background-color: #E9D4D5;
                        }

                        &::before {
                            left: 50%;
                            right: 50%;
                            transition: right 0.2s;
                        }

                        &::after {
                            right: 50%;
                            left: 50%;
                            transition: left 0.2s;
                        }

                        &:hover {
                            &::before {
                                right: 0;
                            }

                            &::after {
                                left: 0;
                            }
                        }
                    }

                    &.active {
                        a {
                            &::before {
                                right: 0;
                            }

                            &::after {
                                left: 0;
                            }
                        }
                    }
                }
            }

            .login-partners {
                position: absolute;
                top: -55px;
                right: 10px;
                color: #E0B6C2;
                font-size: 13px;
                padding: 5px 7px;
                border: 1px solid #D07E93;
                border-radius: 5px;
                text-decoration: none;
                transition: all 0.2s;

                &:hover {
                    color: #E9D4D5;
                    border-color: #E9D4D5;
                }
            }
        }

    }
}