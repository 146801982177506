@-ms-viewport {
    width: device-width;
}

*, *::after, *::before {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    color: $color-text;
    background-color: $bgcolor-body;
    font-family: $font-roboto;
    font-weight: 400;
    font-size: $font-size-root;
    line-height: $line-height-root;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    min-width: $screen-min-width;
    padding-top: 170px;

    @media screen and (min-width: $screen-md) {
        font-size: $font-size-root + 2;
    }
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

h2 {
    font-weight: 300;
    color: $color-main;
    font-size: 36px;
    text-transform: uppercase;
    margin: 0 0 15px 0;

    @media screen and (min-width: $screen-lg) {
        font-size: 44px;
    }

    &.thin {
        font-size: 38px;
        font-weight: 100;
        letter-spacing: 3px;
    }

    &.gray {
        color: $color-gray;
    }
}

h3 {
    font-weight: 400;
    color: $color-main;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0 0 10px 0;

    &.gray {
        color: $color-gray;
    }
}

p {
    margin: 5px 0 1em 0;
}

table{
    border-collapse: collapse;
}